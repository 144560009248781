export class PrinterName {

  /**
   *
   * @param h1Element {HTMLHeadElement}
   */
  constructor(h1Element) {
    this.el = h1Element;
    this._value = '';
  }

  set value(val) {
    this._value += val;
    this.el.innerHTML = this._value
  }

  resetValue() {
    this._value = '';
    this.el.innerHTML = this._value
  }

  setBlinker(val) {
    if (val)
      this.el.classList.add('blinker');
    else
      this.el.classList.remove('blinker');
  }

  async run() {
    this.setBlinker(false);
    for (let i of 'I.Zah') {
      this.value = i;
      await timeout();
    }

    this.setBlinker(true);

    await timeout(1000);
    this.resetValue();

    this.setBlinker(false);
    for (let i of 'Ilya Zaharov') {
      this.value = i;
      await timeout(Math.random() * 350);
    }

    await timeout(500);
    this.value = '&nbsp;';
    this.setBlinker(true);
  }
}

async function timeout(delay = 200) {

  return new Promise(resolve => {
    setTimeout(resolve, delay)
  })
}