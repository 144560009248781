export function isVisible(target) {
  let targetPosition = {
      top: window.pageYOffset + target.getBoundingClientRect().top,
      bottom: window.pageYOffset + target.getBoundingClientRect().bottom
    },
    // Получаем позиции окна
    windowPosition = {
      top: window.pageYOffset,
      bottom: window.pageYOffset + document.documentElement.clientHeight
    };

  return (
    targetPosition.bottom > windowPosition.top
    &&
    targetPosition.top < windowPosition.bottom
  )
}
