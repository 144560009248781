//import './canvas';
import './smoke';
import {isVisible} from "./utils";
import {PrinterName} from "./printerName";
// import '../assets/styles/index.scss';

(function () {
  const emailLink = document.querySelector(".mdi.mdi-email");
  emailLink.addEventListener('click', function (e) {
    e.preventDefault();
    window.location.href = 'mailto:' + generateEmail();
  });

  const emailLink2 = document.querySelector("#contact .mdi.mdi-email");
  emailLink2.addEventListener('click', function (e) {
    e.preventDefault();
    window.location.href = 'mailto:' + generateEmail();
  });


  const firstPage = document.querySelector(".first-page");
  const navBar = document.querySelector('#navbar-');

  const pageNavs = navBar.querySelectorAll('a[href]');
  const ids = [];
  const pages = {};

  for (let v of pageNavs.values()) {
    ids.push(v.hash);
    pages[v.hash] = document.querySelector(v.hash);
  }

  setActiveNav(pageNavs.values(), window.location.hash);

  let timer;
  document.addEventListener('scroll', function () {

    // проверяем  что первая страница в видимости
    navBar.classList.toggle('navbar-fixed', !isVisible(firstPage));

    if (timer) clearTimeout(timer);
    // определяем какая сейчас #hash
    timer = setTimeout(() => {
      for (let key in pages) {
        const el = pages[key];
        if (isVisible(el)) {
          history.replaceState(null, null, key);
          setActiveNav(pageNavs.values(), key);
          break;
        }
      }
    }, 50);
  });

  // Start printer my fio
  new PrinterName(document.querySelector('.printer--name')).run();
})();


function setActiveNav(pages, activeHash) {
  for (let el of pages) {
    const h = el.hash;
    el.parentNode.classList.toggle('active', h === activeHash);
  }
}

const keys = ['ilya', 'zakharov', '30', '//', 'gmail.com'];

function generateEmail() {
  return keys.map(v => (v === '//' ? '@' : v)).join('.').replace('.@.', '@')
}
